import { render, staticRenderFns } from "./listNei.vue?vue&type=template&id=652ac32a&scoped=true&"
import script from "./listNei.vue?vue&type=script&lang=js&"
export * from "./listNei.vue?vue&type=script&lang=js&"
import style0 from "./listNei.vue?vue&type=style&index=0&id=652ac32a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652ac32a",
  null
  
)

export default component.exports